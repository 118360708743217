import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebapiService } from '../webapi.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import moment from 'moment';

@Component({
  selector: 'app-invoiceanalysis',
  templateUrl: './invoiceanalysis.component.html',
  styleUrls: ['./invoiceanalysis.component.scss'],
})
export class InvoiceanalysisComponent implements OnInit {

  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  page=0;
  stypec;
  year='FY_year'
  quarter='cisco_FY_quarter'
  filters={

    fiscal_year:[],
    fiscal_quarter:[]

  }
  filterselection={
    fiscal_year:'',
    fiscal_quarter:''
  }
  dropdownList = [];
    selectedItems = [];
    dropdownSettings:IDropdownSettings;
    endofweek:any;
    fiscal_list:any=[]
  constructor(private router:Router,public service: WebapiService) {
    this.endofweek=moment().endOf('week').format('MM/DD/YYYY');


     this.get_cal(this.endofweek); 
   }

   
   get_cal(enddate){

    this.service.postData({startdate:enddate}, 'get_fiscal_years').then((result) => {
      var temp: any = result;

       this.fiscal_list=JSON.parse(temp.fiscal_list);
       //console.log(this.fiscal_list)

       var year=this.year; 
       this.filters.fiscal_year = this.fiscal_list
        .map((item) => item[year])
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );

        this.filterselection.fiscal_year=this.filters.fiscal_year[this.filters.fiscal_year.length-1];
        this.get_quarter_fiscal_year(this.filterselection.fiscal_year)
      //  this.get_inv_data();


    });
    
   }
   get_quarter_fiscal_year(fiscal_year){

    var year=this.year;
    var quarter=this.quarter;
    
    //////console.log(this.fiscal_list)
    //////console.log(fiscal_year,year)
    var list = this.fiscal_list.filter(function(item:any){
      return item[year]==fiscal_year } ) 
    //////console.log(list)

    this.filters.fiscal_quarter = list
    .map((item) => item[quarter])
    .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
    );

    if(this.filters.fiscal_quarter.length>0)
      this.filterselection.fiscal_quarter=this.filters.fiscal_quarter[this.filters.fiscal_quarter.length-2]
    else
      this.filterselection.fiscal_quarter=this.filters.fiscal_quarter[0]

    this.selectedItems=[]


    this.get_inv_data();
   
  }
  setpage(k){

    this.page=k;
    this.getallfiletype();

  }
  weekly:any=[]
  lanelvl:any=[]
  shipment:any=[]
   getDistinctValuesByKey(arr, key) {
    const values = arr.map(item => item[key]); // Extract the values of the specified key
    return [...new Set(values)]; // Use a Set to filter out duplicates and return as an array
  }
  lanelist:any=[]
  selectedtab=0;
  selected_tab(t){
    this.selectedtab=t;

  }
  topfilter=-1;
  settopfilter(n){
   
    this.topfilter=n;

    this.selectedItems=this.lane_spendlist.slice(0, n).map(item => item.Lane);
    if(this.topfilter!=-1)
     this.filter_data();
    

  }
  quotelist:any=[]
  servicelist:any;

  flaglane=false;

  get_inv_data(){
    this.flaglane=true;
    this.selectedItems=[]
    this.topfilter=-1
    this.lanelist=[]

    this.service.postData({year:this.filterselection.fiscal_year,quarter:this.filterselection.fiscal_quarter}, 'get_invoice_data').then((result) => {
      var temp: any = result;
       //console.log(temp)
      
      this.weekly =JSON.parse(temp.weekly);
      this.lanelvl =JSON.parse(temp.lane_lvl);
      this.shipment =JSON.parse(temp.shipment);

       //console.log(this.weekly);
       //console.log(this.lanelvl);
       //console.log(this.shipment);

       this.lanelist=this.getDistinctValuesByKey(this.lanelvl,'Lane');

       this.lane_spendlist=Object.values(this.groupByAndSumAndAvg(this.shipment,'Lane',['spend','BILLED_WEIGHT'],[]));
       this.lane_spendlist.sort(this.sortByProperty("spend"));
       //console.log(this.lane_spendlist);
   
      //  // Create a map to keep track of the highest spend per lane
      //   const laneSpendMap = {};

      //   // Iterate over each object to get the highest spend for each lane
      //   this.shipment.forEach(item => {
      //     if (!laneSpendMap[item.Lane] || laneSpendMap[item.Lane] < item.spend) {
      //       laneSpendMap[item.Lane] = item.spend;
      //     }
      //   });

      //   // Convert the map to an array of objects, and sort by spend in descending order
      //   this.lanelist = Object.keys(laneSpendMap)
      //     .map(lane => ({ lane, spend: laneSpendMap[lane] }))
      //     .sort((a, b) => b.spend - a.spend);

      //  //console.log(this.lanelist);

      this.flaglane=false;

      this.settopfilter(10);
      this.filter_data();
      }).catch(e => {

        //console.log(e);
      });
  }
  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Lane',
      textField: 'Lane',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  filtered_data_lanelvl:any=[]
  
  groupByMultipleKeysAndAvg = (arr, keys, valueKeys) => {
    return arr.reduce((acc, item) => {
      // Create a group key based on the provided keys
      const groupKey = keys.map(key => item[key]).join('-');
      
      // Initialize group if it doesn't exist
      if (!acc[groupKey]) {
        // Store the first item for reference and initialize aggregates for value columns
        acc[groupKey] = { 
          originalData: { ...item },  // Store original data for the first item of the group
          aggregates: valueKeys.reduce((obj, key) => {
            obj[key] = { sum: 0, count: 0 };
            return obj;
          }, {})
        };
      }
      
      // Add value to sum and increment count for each value key
      valueKeys.forEach(valueKey => {
        acc[groupKey].aggregates[valueKey].sum += item[valueKey];
        acc[groupKey].aggregates[valueKey].count += 1;
      });
      
      return acc;
    }, {});
  };

  lane_spendlist:any=[];
  total_week:any=[]

  totals_by_servicelevel_week
  cumulative_spend:any=[]
  totals_by_servicelevel_week_carrier:any=[]
  distinctpartner:any=[]
  
  total_quarter={
    spend:0,
    BILLED_WEIGHT:0
  };
  total_quarter_service:any=[]
  cal_quarter(){
    var spend=0,weight=0;
    this.total_week.forEach(week => {

      spend=spend+week.spend;
      weight=weight+week.BILLED_WEIGHT;

    });
    this.total_quarter.spend=spend;
    this.total_quarter.BILLED_WEIGHT=weight;


  }
  filter_data(){


    var ctx=this;
    this.filtered_data_lanelvl=this.lanelvl.filter(function(item:any){return ctx.selectedItems.indexOf(item.Lane)>=0})
    this.filtered_shipmentdata_lane=this.shipment.filter(res=>{ return ctx.selectedItems.indexOf(res.Lane)>=0 })

    var totals_by_price_model_week=Object.values(this.groupBymultipleAndSumAndAvg(this.filtered_shipmentdata_lane,['Week_Start','QType'],['spend','BILLED_WEIGHT'],["ATA","ATD","DTA","DTD"]));
   // Convert the result object to an array and add averages to it

   this.totals_by_price_model_week = Object.values(totals_by_price_model_week).map(group => {
    // Calculate average for each avg column
    ["ATA","ATD","DTA","DTD"].forEach(col => {
      group[`${col}_avg`] = group[`${col}_sum`] / group[`${col}_count`];
      // Clean up the temporary sum and count keys
      delete group[`${col}_sum`];
      delete group[`${col}_count`];
    });
    return group;
  });
    //console.log(this.totals_by_price_model_week);


    var totals_by_servicelevel_week=Object.values(this.groupBymultipleAndSumAndAvg(this.filtered_shipmentdata_lane,['Week_Start','SERVICE_LEVEL_CISCO'],['spend','BILLED_WEIGHT'],[]));
    // Convert the result object to an array and add averages to it
    
    this.totals_by_servicelevel_week = Object.values(totals_by_servicelevel_week).map(group => {
     // Calculate average for each avg column
     [].forEach(col => {
       group[`${col}_avg`] = group[`${col}_sum`] / group[`${col}_count`];
       // Clean up the temporary sum and count keys
       delete group[`${col}_sum`];
       delete group[`${col}_count`];
     });
     return group;
   });
     //console.log(this.totals_by_servicelevel_week);

    this.total_week=Object.values(this.groupByAndSumAndAvg(this.totals_by_price_model_week,'Week_Start',['spend','BILLED_WEIGHT'],[]));
    this.total_quarter_service=Object.values(this.groupByAndSumAndAvg(this.filtered_shipmentdata_lane,'SERVICE_LEVEL_CISCO',['spend','BILLED_WEIGHT'],[]));
    this.totals_by_servicelevel_week_carrier=Object.values(this.groupBymultipleAndSumAndAvg(this.filtered_shipmentdata_lane,['Week_Start','CARRIER','SERVICE_LEVEL_CISCO'],['spend','BILLED_WEIGHT'],[]));

    
    //console.log(this.total_quarter_service)
    this.distinctpartner= this.getDistinctValuesByKey(this.totals_by_servicelevel_week_carrier,"CARRIER");
    //console.log(this.distinctpartner)

    this.generate_graph_data();
    this.create_partner_cumulative();
   this.cal_quarter();

  }
 
  partner_cumlative:any=[]
  create_partner_cumulative(){

    var weeks=JSON.parse(JSON.stringify(this.filtered_week)).reverse();
    this.partner_cumlative=[]

    //console.log(weeks);
    this.distinctpartner.forEach(element => {
      var partner:any;

      var weeklist:any=[]
      var stdc=0,defc=0,expc=0,otherc=0;

      weeks.forEach(week => {

      var partnerlist = this.totals_by_servicelevel_week_carrier.filter(res=>{return res.CARRIER==element && res.Week_Start==week});
      //console.log(partnerlist);

      var std=0,def=0,exp=0,other=0;
      partnerlist.forEach(data => {

       // //console.log(data);
        // var spendobj:any;
        // stdc=stdc+data.std;
        // var std=data.filter (res=> { return res.Week_Start==data.Week_Start && res.SERVICE_LEVEL_CISCO=='STD'});
        // var exp=data.filter (res=> { return res.Week_Start==data.Week_Start && res.SERVICE_LEVEL_CISCO=='EXP'});
        // var def=data.filter (res=> { return res.Week_Start==data.Week_Start && res.SERVICE_LEVEL_CISCO=='DEF'});
        // var other=data.filter (res=> { return res.Week_Start==data.Week_Start && res.SERVICE_LEVEL_CISCO=='OTHER'});
        
        if(data.SERVICE_LEVEL_CISCO=='STD')
        {
          stdc=stdc+data.spend;
          std=data.spend;
        }
        if(data.SERVICE_LEVEL_CISCO=='DEF')
        {
            defc=defc+data.spend;
            def=data.spend;
        }
        if(data.SERVICE_LEVEL_CISCO=='EXP')
        {
              expc=expc+data.spend;
              exp=data.spend;
        }
        if(data.SERVICE_LEVEL_CISCO=='OTHER')
        {
                otherc=otherc+data.spend;
                other=data.spend;
        }
      });

      weeklist.push({week:week,std:std,stdc:stdc,defc:defc,def:def,expc:expc,exp:exp,otherc:otherc,other:other})
      });
      this.partner_cumlative.push({partner:element,week:weeklist});

      
    });
    //console.log(this.partner_cumlative);
    this.showpartnerc(0);
    
  }
  groupByAndSumAndAvg(data, groupByColumn, sumColumns, avgColumns) {
    return data.reduce((result, currentItem) => {
      // Get the key for the group (e.g., category)
      const groupKey = currentItem[groupByColumn];
  
      // If the group doesn't exist in the result, initialize it
      if (!result[groupKey]) {
        result[groupKey] = {
          [groupByColumn]: groupKey, // Add the group key for reference
          count: 0, // To count the number of items in the group
          ...sumColumns.reduce((acc, col) => ({ ...acc, [`${col}`]: 0 }), {}),
          ...avgColumns.reduce((acc, col) => ({ ...acc, [`${col}`]: 0 }), {}),
        };
      }
  
      // Sum the columns specified in sumColumns
      sumColumns.forEach(col => {
        result[groupKey][`${col}`] += currentItem[col] || 0;
      });
  
      // Sum the columns specified in avgColumns for calculating average later
      avgColumns.forEach(col => {
        result[groupKey][`${col}`] += currentItem[col] || 0;
      });
  
      // Increase the count for calculating the average later
      result[groupKey].count += 1;
  
      return result;
    }, {});
  }
   groupBymultipleAndSumAndAvg(data, groupByColumns, sumColumns, avgColumns) {
    return data.reduce((result, currentItem) => {
      // Generate a unique key for the combination of group columns (category + subcategory)
      const groupKey = groupByColumns.map(col => currentItem[col]).join('_');
  
      // If the group doesn't exist in the result, initialize it
      if (!result[groupKey]) {
        // Initialize the group with the groupByColumns and the required properties
        result[groupKey] = {
          // Store the group keys (category, subcategory) in the result
          ...groupByColumns.reduce((acc, col) => {
            acc[col] = currentItem[col];
            return acc;
          }, {}),
          count: 0, // To count the number of items in the group
          ...sumColumns.reduce((acc, col) => ({ ...acc, [col]: 0 }), {}),
          ...avgColumns.reduce((acc, col) => ({ ...acc, [`${col}_sum`]: 0, [`${col}_count`]: 0 }), {}),
        };
      }
  
      // Sum the columns specified in sumColumns
      sumColumns.forEach(col => {
        result[groupKey][col] += currentItem[col] || 0;
      });
  
      // For avgColumns, sum the values and also count them separately
      avgColumns.forEach(col => {
        result[groupKey][`${col}_sum`] += currentItem[col] || 0;
        result[groupKey][`${col}_count`] += 1; // Track how many times this column was encountered
      });
  
      // Increase the count for calculating averages later
      result[groupKey].count += 1;
  
      return result;
    }, {});
  }
  
  // Calculate averages after summing the data
   calculateAverages(groupedData, avgColumns) {
    Object.keys(groupedData).forEach(groupKey => {
      const group = groupedData[groupKey];
      avgColumns.forEach(col => {
        const sum = group[`${col}`];
        const count = group.count;
        // Calculate average if count is greater than 0
        group[`${col}`] = count > 0 ? sum / count : 0;
      });
    });
    return groupedData;
  }
  filtered_week:any=[]
  lane_lvl_chart:any;
  lane_lvl_data:any=[];
  lane_lvl_Service_chart:any;
  lane_lvl_Service_data:any=[];

  groupByAndAvg(data, groupKey, avgKeys) {
    // Step 1: Group the data by the given key
    const groupedData = data.reduce((acc, item) => {
      const groupValue = item[groupKey];
      if (!acc[groupValue]) {
        acc[groupValue] = [];
      }
      acc[groupValue].push(item);
      return acc;
    }, {});
  
    // Step 2: Calculate the average for each key in `avgKeys`
    const result = Object.keys(groupedData).map(groupValue => {
      const groupItems = groupedData[groupValue];
  
      // Initialize the average object for this group
      const avgObj = { Week_Start: groupValue };
  
      avgKeys.forEach(key => {
        // Filter out null or undefined values
        const validValues = groupItems
          .map(item => item[key])
          .filter(value => value !== null && value !== undefined);
  
        // Compute the average of valid values, or set it to null if no valid values exist
        const avg = validValues.length > 0
          ? validValues.reduce((sum, value) => sum + value, 0) / validValues.length
          : null;
  
        avgObj[key] = avg;
      });
  
      return avgObj;
    });
  
    return result;
  }
  groupByAndAverage(data, groupByField, valueFields) {
    // Group the data by the groupByField
    const groupedData = data.reduce((acc, item) => {
      const groupKey = item[groupByField];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    }, {});
  
    // Calculate the average for each group
    const result = Object.keys(groupedData).map(groupKey => {
      const groupItems = groupedData[groupKey];
      let totalSum = 0;
      let totalNonNullCount = 0;
  
      // For each row in the group, sum the non-null values
      groupItems.forEach(item => {
        valueFields.forEach(field => {
          if (item[field] !== null) {
            totalSum += item[field];
            totalNonNullCount++;
          }
        });
      });
  
      // Calculate the average only for non-null values
      const average = totalNonNullCount > 0 ? totalSum / totalNonNullCount : null;
  
      return {
        [groupByField]: groupKey,
        avg: average
      };
    });
  
    return result;
  }
  weeklavg:any=[]
  weeklserviceavgstd:any=[]
  weeklserviceavgdef:any=[]
  weeklserviceavgexp:any=[]
  weeklserviceavgother:any=[]

  totals_by_price_model_week:any=[]
  generate_graph_data(){
     var result=this.groupByMultipleKeysAndAvg(this.filtered_data_lanelvl,['Week_Start','QType'],['WklyQuoteTypePct']);
     var lanlvl_quote = Object.keys(result).map(groupKey => {
      const group = result[groupKey];
      const avgGroup = { ...group.originalData, groupKey };
    
      // Calculate the average for each valueKey and add to the result
      Object.keys(group.aggregates).forEach(valueKey => {
        const valueData = group.aggregates[valueKey];
        avgGroup[valueKey] = valueData.sum / valueData.count;
      });
    
      return avgGroup;
    });
    lanlvl_quote.sort(this.sortByProperty("Date"));

    //console.log(lanlvl_quote);

    var result=this.groupByMultipleKeysAndAvg(this.filtered_data_lanelvl,['Week_Start','SERVICE_LEVEL_CISCO'],['WklySLPct']);
    var lanlvl_service = Object.keys(result).map(groupKey => {
     const group = result[groupKey];
     const avgGroup = { ...group.originalData, groupKey };
   
     // Calculate the average for each valueKey and add to the result
     Object.keys(group.aggregates).forEach(valueKey => {
       const valueData = group.aggregates[valueKey];
       avgGroup[valueKey] = valueData.sum / valueData.count;
     });
   
     return avgGroup;
   });

   lanlvl_service.sort(this.sortByProperty("Date"));
   this.filtered_week=this.getDistinctValuesByKey(lanlvl_service,'Week_Start');

   //console.log(lanlvl_service);
   //console.log(this.filtered_week);

   var combochart = {
    chartType: 'ColumnChart',
    dataTable: [ 
      ['Category', 'Series1', 'Series2', 'Series3'],
      ['A', 10, 20, 30],
      ['B', 15, 25, 35],
      ['C', 25, 15, 20]
    ], // Example data table: Ensure this contains your combined data
    options: {
      bar: { groupWidth: '75%' },
      vAxes: {
        0: {
          gridlines: { color: 'transparent' },
          textPosition: 'none',
        },
        1: {
          gridlines: { color: 'transparent' },
          textPosition: 'none',
        },
      },
      series: {
        0: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
        1: { type: 'line', targetAxisIndex: 1 }, // Line series
        2: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
        3: { type: 'line', targetAxisIndex: 1 }, // Line series
        4: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
        5: { type: 'line', targetAxisIndex: 1 }, // Line series
        6: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
        7: { type: 'line',targetAxisIndex: 1 }, // Line series


      },

      isStacked: true,
      legend: { position: 'top', alignment: 'center' ,maxLines: 2,textStyle: {fontSize: 10}},

      hAxis: {
        gridlines: { color: 'transparent' },
      },
      height: 320,
      pointSize: 6,
      colors: [
        '#f9e79f',  // Light teal
        '#d4ac0d',
        '#d4e6f1',  // Light yellow-green
        '#2874a6',
        '#d5f5e3',  // Light green
        '#239b56',
        
                
        '#d7dbdd',  // Light blue
        '#839192',
      ],
      annotations: {
        textStyle: {
          fontSize: 8,
          color: '#000', // White text for visibility
        },
        alwaysOutside: false,
      },
      'chartArea': {'width': '85%', 'height': '60%'},

    },
  };

  this.lane_lvl_data=[];
  this.lane_lvl_data.push(['Week','ATA(%)',{role:'annotation'},'ATA(AVG)','ATD(%)',{role:'annotation'},'ATD(AVG)','DTA(%)',{role:'annotation'},'DTA(AVG)','DTD(%)',{role:'annotation'},'DTD(AVG)'])

  this.lane_lvl_Service_data=[];
  this.lane_lvl_Service_data.push(['Week','STD(%)',{role:'annotation'},'STD(AVG)','DEF(%)',{role:'annotation'},'DEF(AVG)','EXP(%)',{role:'annotation'},'EXP(AVG)','OTHER(%)',{role:'annotation'},'OTHER(AVG)'])
  
  //console.log(lanlvl_quote);
  this.weeklavg = this.groupByAndAvg(lanlvl_quote, 'Week_Start', ['WklyAvgATA', 'WklyAvgATD', 'WklyAvgDTA', 'WklyAvgDTD']);

  //console.log(this.weeklavg)

  this.weeklserviceavgstd =this.groupByAndAverage(lanlvl_service.filter(res=>{return res.SERVICE_LEVEL_CISCO=='STD'}), 'Week_Start', ['WklyAvgATA', 'WklyAvgATD', 'WklyAvgDTA', 'WklyAvgDTD']);
  this.weeklserviceavgdef =this.groupByAndAverage(lanlvl_service.filter(res=>{return res.SERVICE_LEVEL_CISCO=='EXP'}), 'Week_Start', ['WklyAvgATA', 'WklyAvgATD', 'WklyAvgDTA', 'WklyAvgDTD']);
  this.weeklserviceavgexp =this.groupByAndAverage(lanlvl_service.filter(res=>{return res.SERVICE_LEVEL_CISCO=='DEF'}), 'Week_Start', ['WklyAvgATA', 'WklyAvgATD', 'WklyAvgDTA', 'WklyAvgDTD']);
  this.weeklserviceavgother =this.groupByAndAverage(lanlvl_service.filter(res=>{return res.SERVICE_LEVEL_CISCO=='OTHER'}), 'Week_Start', ['WklyAvgATA', 'WklyAvgATD', 'WklyAvgDTA', 'WklyAvgDTD']);

  //console.log(this.weeklserviceavgstd)
  //console.log(this.weeklserviceavgdef)
  //console.log(this.weeklserviceavgexp)


  this.cumulative_spend=[]
  var std_c=0,def_c=0,exp_c=0,other_c=0;
  this.filtered_week.forEach(week => {


    

    var week_total=this.total_week.filter(res=>{return res.Week_Start==week})[0];
    //console.log(week_total);

    var ata=this.totals_by_price_model_week.filter (res=> { return res.Week_Start==week && res.QType=='ATA'});

    var dta=this.totals_by_price_model_week.filter (res=> { return res.Week_Start==week && res.QType=='DTA'});
    var atd=this.totals_by_price_model_week.filter (res=> { return res.Week_Start==week && res.QType=='ATD'});
    var dtd=this.totals_by_price_model_week.filter (res=> { return res.Week_Start==week && res.QType=='DTD'});
   


    // var ata=lanlvl_quote.filter (res=> { return res.Week_Start==week && res.QType=='ATA'});
    // var dta=lanlvl_quote.filter (res=> { return res.Week_Start==week && res.QType=='DTA'});
    // var atd=lanlvl_quote.filter (res=> { return res.Week_Start==week && res.QType=='ATD'});
    // var dtd=lanlvl_quote.filter (res=> { return res.Week_Start==week && res.QType=='DTD'});


    var std=this.totals_by_servicelevel_week.filter (res=> { return res.Week_Start==week && res.SERVICE_LEVEL_CISCO=='STD'});
    var exp=this.totals_by_servicelevel_week.filter (res=> { return res.Week_Start==week && res.SERVICE_LEVEL_CISCO=='EXP'});
    var def=this.totals_by_servicelevel_week.filter (res=> { return res.Week_Start==week && res.SERVICE_LEVEL_CISCO=='DEF'});
    var other=this.totals_by_servicelevel_week.filter (res=> { return res.Week_Start==week && res.SERVICE_LEVEL_CISCO=='OTHER'});
    
    //console.log(std);
    //console.log(exp);
    //console.log(def);
    //console.log(other);

    var atav=0,ataa='',atdv=0,atda='',dtav=0,dtaa='',dtdv=0,dtda='',atag=undefined,atdg=undefined,dtdg=undefined,dtag=undefined;
   
    
    if(ata.length>0)     
    {
        atav=(ata[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
        atag=ata[0].ATA_avg;
        ataa=Math.round(atav)+"%";
        
    }
    if(dta.length>0)     
    {
      dtav=(dta[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
      dtag=dta[0].DTA_avg;

      dtaa=''+Math.round(dtav)+"%"
    }
    if(atd.length>0)    {
      atdv=(atd[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
      atdg=atd[0].ATD_avg

      atda=''+Math.round(atdv)+"%"
    } 
    if(dtd.length>0) 
      {
        dtdv=(dtd[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
        dtdg=dtd[0].DTD_avg
  

         dtda=''+Math.round(dtdv)+"%"
      }    

    var stdv=0,stda='',defv=0,defa='',expv=0,expa='',otherv=0,othera='',stdavg=null,defg=null,expg=null,otherg=0;
    var stdpend=0,defspend=0,expspend=0,otherspend=0;

    if(std.length>0)     
    {
      stdv=(std[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
      stda=''+Math.round(stdv)+"%"    
      //console.log(std[0],std[0].spend,std[0].BILLED_WEIGHT)
      stdavg=(std[0].spend/std[0].BILLED_WEIGHT)  
      stdpend=std[0].spend;

      // var stdavga=this.weeklserviceavgstd.filter (res=> { return res.Week_Start==week})

      // if(stdavga.length>0)
      //   stdavg=stdavga[0].avg

    }
    if(def.length>0)  
    {
      defv=(def[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
      defa=''+Math.round(defv)+"%"

      defg=(def[0].spend/def[0].BILLED_WEIGHT)  

      defspend=def[0].spend;
    }  
    if(exp.length>0){
      
      expv=(exp[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
      expa=''+Math.round(expv)+"%"
      expg=(exp[0].spend/exp[0].BILLED_WEIGHT)  

      expspend=exp[0].spend;

    }     
    if(other.length>0){

      otherv=(other[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
      othera=''+Math.round(otherv)+"%"
      otherg=(otherg[0].spend/otherg[0].BILLED_WEIGHT)  
      otherspend=other[0].spend;

    }
  

  // var spendobject = {week:week,std:stdpend,std_cumlative:std_c,def:defspend,def_cumlative:def_c,exp:expspend,exp_cumlative:exp_c+expspend,other:otherspend,other_cumlative:other_c+otherspend};
  // this.cumulative_spend.push(spendobject); 


    this.lane_lvl_data.push([week,atav,ataa,atag,atdv,atda,atdg,dtav,dtaa,dtag,dtdv,dtda,dtdg]);
    
    this.lane_lvl_Service_data.push([week,stdv,stda,stdavg,defv,defa,defg,expv,expa,expg,otherv,othera,otherg]);

   

  });

  var weeks=JSON.parse(JSON.stringify(this.filtered_week)).reverse();

  //console.log(weeks);
  weeks.forEach(week => {

    //console.log(week);

    var std=this.totals_by_servicelevel_week.filter (res=> { return res.Week_Start==week && res.SERVICE_LEVEL_CISCO=='STD'});
    var exp=this.totals_by_servicelevel_week.filter (res=> { return res.Week_Start==week && res.SERVICE_LEVEL_CISCO=='EXP'});
    var def=this.totals_by_servicelevel_week.filter (res=> { return res.Week_Start==week && res.SERVICE_LEVEL_CISCO=='DEF'});
    var other=this.totals_by_servicelevel_week.filter (res=> { return res.Week_Start==week && res.SERVICE_LEVEL_CISCO=='OTHER'});

    var stdv=0,stda='',defv=0,defa='',expv=0,expa='',otherv=0,othera='',stdavg=null,defg=null,expg=null,otherg=0;
    var stdpend=0,defspend=0,expspend=0,otherspend=0;

    if(std.length>0)     
    {
      // stdv=(std[0].BILLED_WEIGHT/week_total.BILLED_WEIGHT)*100
    
      stdpend=std[0].spend;

      // var stdavga=this.weeklserviceavgstd.filter (res=> { return res.Week_Start==week})

      // if(stdavga.length>0)
      //   stdavg=stdavga[0].avg

    }
    if(def.length>0)  
    {
    

      defspend=def[0].spend;
    }  
    if(exp.length>0){
      
     

      expspend=exp[0].spend;

    }     
    if(other.length>0){

       
      otherspend=other[0].spend;

    }
    std_c=std_c+stdpend
    def_c=def_c+defspend
    exp_c=exp_c+expspend
    other_c=other_c+otherspend

    var spendobject = {week:week,std:stdpend,std_cumlative:std_c,def:defspend,def_cumlative:def_c,exp:expspend,exp_cumlative:exp_c,other:otherspend,other_cumlative:other_c};
   this.cumulative_spend.push(spendobject); 

    
  });

  //console.log(this.cumulative_spend);

  var max=this.lane_lvl_data.length-1;
  var ata=0,atd=0,dta=0,dtd=0;
  this.lane_lvl_data.slice(1,this.lane_lvl_data.length).forEach(lane => {
   
    if(lane[3]==null)
       ata++;
    if(lane[6]==null)
      atd++;
    if(lane[9]==null)
      dta++;
    if(lane[12]==null)
      dtd++;

  });
  //console.log(ata,atd,dta,dtd,max);
  if(ata==max)
  {
      this.lane_lvl_data[1][3]=0;
  }
  if(atd==max)
    {
        this.lane_lvl_data[1][6]=0;
    }
    if(dta==max)
      {
          this.lane_lvl_data[1][9]=0;
      }
      if(dtd==max)
        {
            this.lane_lvl_data[1][12]=0;
        }
        //console.log(this.lane_lvl_data);

   
   combochart.dataTable=this.lane_lvl_data;

   if(this.lane_lvl_data.length>10){
     combochart.options.bar.groupWidth='75%';
   }else if(this.lane_lvl_data.length>6&&this.lane_lvl_data.length<11){
     combochart.options.bar.groupWidth='60%';

   }
   else if(this.lane_lvl_data.length>4&&this.lane_lvl_data.length<7){
     combochart.options.bar.groupWidth='40%';

   }else{
     combochart.options.bar.groupWidth='20%';

   }

   this.lane_lvl_chart=JSON.parse(JSON.stringify(combochart));
   //console.log(this.lane_lvl_data);

   var max=this.lane_lvl_Service_data.length-1;
  var ata=0,atd=0,dta=0,dtd=0;
  this.lane_lvl_Service_data.slice(1,this.lane_lvl_Service_data.length).forEach(lane => {
   
    if(lane[3]==null)
       ata++;
    if(lane[6]==null)
      atd++;
    if(lane[9]==null)
      dta++;
    if(lane[12]==null)
      dtd++;

  });
  //console.log(ata,atd,dta,dtd,max);
  if(ata==max)
  {
      this.lane_lvl_Service_data[1][3]=0;
  }
  if(atd==max)
    {
        this.lane_lvl_Service_data[1][6]=0;
    }
    if(dta==max)
      {
          this.lane_lvl_Service_data[1][9]=0;
      }
      if(dtd==max)
        {
            this.lane_lvl_Service_data[1][12]=0;
        }
        //console.log(this.lane_lvl_Service_data);


   combochart.dataTable=this.lane_lvl_Service_data;
   
   if(this.lane_lvl_Service_data.length>10){
     combochart.options.bar.groupWidth='75%';
   }else if(this.lane_lvl_Service_data.length>6&&this.lane_lvl_data.length<11){
     combochart.options.bar.groupWidth='50%';

   }
   else if(this.lane_lvl_Service_data.length>4&&this.lane_lvl_data.length<7){
     combochart.options.bar.groupWidth='30%';

   }else{
     combochart.options.bar.groupWidth='10%';

   }
   this.lane_lvl_Service_chart=JSON.parse(JSON.stringify(combochart));
   //console.log(this.lane_lvl_Service_data);


  //  cumlative spend

  var LineChartdata = {
    chartType: 'AreaChart',
    dataTable: [],
    options: {
      options: {

        title: "Spend",
        
      },
      hAxis: {  gridlines: {
        color: 'transparent'
      }},
      vAxis :{
        gridlines: {
          color: 'transparent'
        }
      },
      
      pointSize:3,
      // legend: {position: 'top', textStyle: {fontSize: 14}}

      legend: { position: 'top', alignment: 'center' ,textStyle: {fontSize: 10}},
      
      colors: ['#f7dc6f','#aed6f1','#7dcea0','#d5dbdb'],
      
      'chartArea': {'width': '80%'},
    height:340,
    }

  }

  var datatable:any=[["Week","STD","DEF","EXP","OTHER"]]

  this.cumulative_spend.forEach(spend => {

    datatable.push([spend.week,spend.std_cumlative,spend.def_cumlative,spend.exp_cumlative,spend.other_cumlative]);
    
  });

  LineChartdata.dataTable=datatable;
  this.cumlative_chart=JSON.parse(JSON.stringify(LineChartdata));

  //console.log(this.cumlative_chart.dataTable);
  }
  cumlative_chart:any;
  sortByProperty(property){  
    return function(b,a){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
 }
 selectedpartner=0;
 showpartnerc(index){
  //  cumlative spend
  this.selectedpartner=index;
  

  var partnerlist= this.partner_cumlative[index].week;


  var LineChartdata = {
    chartType: 'AreaChart',
    dataTable: [],
    options: {
      options: {

        title: "Spend",
        
      },
      hAxis: {  gridlines: {
        color: 'transparent'
      }},
      vAxis :{
        gridlines: {
          color: 'transparent'
        }
      },
      
      pointSize:3,
      // legend: {position: 'top', textStyle: {fontSize: 14}}

      legend: { position: 'top', alignment: 'center' ,textStyle: {fontSize: 12}},
      
      colors: ['#f7dc6f','#aed6f1','#7dcea0','#d5dbdb'],
      
      'chartArea': {'width': '80%'},
    height:340,
    }

  }

  var datatable:any=[["Week","STD","DEF","EXP","OTHER"]]

  partnerlist.forEach(spend => {

    datatable.push([spend.week,spend.stdc,spend.defc,spend.expc,spend.otherc]);
    
  });

  LineChartdata.dataTable=datatable;
  this.cumlative_chart_partner=JSON.parse(JSON.stringify(LineChartdata));

 }
 cumlative_chart_partner:any;
  onItemSelect(e){
    //console.log(this.selectedItems);
  //  this.filter_data();

  }

  selectedquote="";
  filtered_shipmentdata:any=[];
  filtered_shipmentdata_lane:any=[];
  carrier_piechart:any=[]
  carrierlist:any=[]
  selectedweek="";
  gflag=0;
  
  filtered_ship_week:any=[]
  selectweek(e){


    this.gflag=0;

    var quot=e.columnLabel.split('(')[0];
    if(quot==''||quot==null)
      return;

    // console.log(e.row,e.columnLabel,quot)

    this.selectedquote=quot;
    var rowindex=e.row;
    this.selectedweek=this.filtered_week[rowindex];
    this.filtered_ship_week=this.filtered_shipmentdata_lane.filter(res=>{ return res.Week_Start==this.selectedweek  })

    this.quotelist=this.getDistinctValuesByKey(this.filtered_ship_week, 'QType');

    
//console.log(this.quotelist);
   

    this.showpricedetails(quot);
   

    this.openSidebar();

  }
  pagelog=0;
  next(){
    if(this.pagelog<(this.filtered_shipmentdata.length/20)-1)
       this.pagelog++;
    
  }
  prev(){
    if(this.pagelog>0)
     this.pagelog--;
  }
  saveData:any=[]
  saveAsCSV_p(e) {
    // Select the sample data based on the condition (e.g., 'IM' or default)
    let sampleJson: any = e == 'IM' ? this.filtered_shipmentdata : this.filtered_shipmentdata;
    this.saveData = [];
    
    // Create an anchor element to trigger the download
    let a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    
    // Convert the JSON data to CSV
    let csvData = this.ConvertToCSV(sampleJson);
    
    // Create a Blob from the CSV string
    let blob = new Blob([csvData], { type: 'text/csv' });
    
    // Create an object URL for the Blob
    let url = window.URL.createObjectURL(blob);
    
    // Set the download attribute and trigger the click to download the file
    a.href = url;
    a.download = 'shipment_'+this.filterselection.fiscal_year+'_'+this.filterselection.fiscal_quarter+'.csv';
    a.click();
  }
  ConvertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    ////console.log(array)
    let str = '';
    let row = "";
    for (let index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    // row="Lane,PARTNER,Previous Weeks Received,Backlog Moved,This Week Received,This Week Moved,Total Moved,Backlog Moved,Backlog Received,Total Backlog,Total Received,Total Volume"
    // row="Lane,PARTNER,Privious,pw_received_twcob,tw_received,tw_received_twcob,tw_moved,backlog_moved,backlog_received,total_backlog,total_received,total_volume,Baseallocation,Flex,MinAllocaion"

    ////console.log(row)
    //append Label row with line break
    str += row + '\r\n';
  
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  showpricedetails_week(week){

    this.selectedweek=week
    this.filtered_ship_week=this.filtered_shipmentdata_lane.filter(res=>{ return res.Week_Start==this.selectedweek  })
    if(this.gflag==0)
       this.quotelist=this.getDistinctValuesByKey(this.filtered_ship_week, 'QType');
   else
       this.quotelist=this.getDistinctValuesByKey(this.filtered_ship_week, 'SERVICE_LEVEL_CISCO');


  

    this.showpricedetails(this.selectedquote)
  }
  showpricedetails(quot){
    this.selectedquote=quot;
    var ctx=this;
    //console.log(this.gflag)
    if(this.gflag==0)
      this.filtered_shipmentdata=this.filtered_ship_week.filter(res=>{ return res.QType==quot  })
    else
       this.filtered_shipmentdata=this.filtered_ship_week.filter(res=>{ return res.SERVICE_LEVEL_CISCO==quot  })

    //console.log(this.filtered_shipmentdata)

    const groupedData = this.groupByAndSumAndAvg(this.filtered_shipmentdata, 'CARRIER', ['BILLED_WEIGHT','spend'], [quot]);
    const finalData = this.calculateAverages(groupedData, [quot]);
    this.carrierlist=Object.values(finalData);
    //console.log(this.carrierlist);

    var carrier_piechart = {
      chartType: 'PieChart',
      dataTable: [],
       options: {
        options: {
  
          title: 'Total Booked',
          
        },
        'chartArea': {'width': '90%', 'height': '70%'},

        pieHole:0.4,
        pieSliceTextStyle: {
          color: 'black',
          fontSize:9
        },
        
        pieStartAngle: 180,

        legend: { position: 'top', alignment: 'center' },
        colors: ['#5C6BC0','#C5CAE9','#76d7c4','#d7bde2','#f0b27a','#ccd1d1','#52be80']
        ,

      height:180,
      }
    }
    var piedata=[];
    //console.log(this.carrierlist);
    piedata.push(["CARRIER","BILLED_WEIGHT"])
    this.carrierlist.forEach(car => {
      piedata.push([car.CARRIER,car.BILLED_WEIGHT]);
      
    });
    //console.log(piedata);
    carrier_piechart.dataTable=piedata;
    this.carrier_piechart=JSON.parse(JSON.stringify(carrier_piechart));
  }
  selectweek_service(e){
   
    this.gflag=1;

    //console.log(e)


    var quot=e.columnLabel.split('(')[0];
    if(quot==''||quot==null)
      return;

    this.selectedquote=quot;
    var rowindex=e.row;
    this.selectedweek=this.filtered_week[rowindex];
    this.filtered_ship_week=this.filtered_shipmentdata_lane.filter(res=>{ return res.Week_Start==this.selectedweek  })

    this.quotelist=this.getDistinctValuesByKey(this.filtered_ship_week, 'SERVICE_LEVEL_CISCO');

    
//console.log(this.quotelist);
   

    this.showpricedetails(quot);
   

    this.openSidebar();

    // var quot=e.columnLabel.split('(')[0];
    // this.selectedquote=quot;
    // var rowindex=e.row;
    // this.selectedweek=this.filtered_week[rowindex];

    // //console.log(quot,this.filtered_week[rowindex],this.selectedItems[0]);

    // var ctx=this;
    // this.filtered_shipmentdata=this.filtered_shipmentdata_lane.filter(res=>{ return res.Week_Start==this.filtered_week[rowindex] && res.SERVICE_LEVEL_CISCO==quot  })
    // //console.log(this.filtered_shipmentdata);

    // const groupedData = this.groupByAndSumAndAvg(this.filtered_shipmentdata, 'CARRIER', ['BILLED_WEIGHT'], []);
    // const finalData = this.calculateAverages(groupedData, []);
    // this.carrierlist=Object.values(finalData);

    // var carrier_piechart = {
    //   chartType: 'PieChart',
    //   dataTable: [],
    //    options: {
    //     options: {
  
    //       title: 'Total Booked',
          
    //     },
    //     'chartArea': {'width': '90%', 'height': '70%'},

    //     pieHole:0.4,
    //     pieSliceTextStyle: {
    //       color: 'black',
    //       fontSize:9
    //     },
        
    //     pieStartAngle: 180,

    //     legend: { position: 'top', alignment: 'center' },
    //     colors: ['#5C6BC0','#C5CAE9']
    //     ,

    //   height:180,
    //   }
    // }
    // var piedata=[];
    // //console.log(this.carrierlist);
    // piedata.push(["CARRIER","BILLED_WEIGHT"])
    // this.carrierlist.forEach(car => {
    //   piedata.push([car.CARRIER,car.BILLED_WEIGHT]);
      
    // });
    // //console.log(piedata);
    // carrier_piechart.dataTable=piedata;
    // this.carrier_piechart=JSON.parse(JSON.stringify(carrier_piechart));

    
   

    // this.openSidebar();

  }

  isSidebarOpen = false;

  openSidebar() {
    this.isSidebarOpen = true;
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }
  onSelectAll(e){
    //console.log(this.selectedItems);

  }
  gototraining(){
    
    this.router.navigateByUrl('/demandsensingnew');


   }
  GOtoNExtPage_2() {
    this.router.navigateByUrl('/operational-concern');

  }
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operational-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }

  uploadstatus=false;
  resultr:any;
  date=""
  uploadstatus_message=""
  upload(e,type,tp,date)
   {
     
       this.uploadstatus=true;
       this.uploadstatus_message="Please wait.."
 
       var form_data = new FormData();  
             ////console.log(model);
             //console.log(e.target.files)
             for(var i=0;i<e.target.files.length;i++)
                 form_data.append('file', e.target.files.item(i)); 
             
                 form_data.append('type', type); 
                 form_data.append('uby', localStorage.getItem("email"));
                 form_data.append('tp', tp); 
                 form_data.append('date', date); 
 

                 this.service.postData1(form_data, 'upload_type').then((result) => {
                   var temp: any = result;
                   this.resultr = temp
                   //console.log("2");
                   this.uploadstatus=true;
                   this.uploadstatus_message="File uploaded successfully"
 
                 

                   
             
             }).catch(e => {
               this.uploadstatus=true;
               this.uploadstatus_message="Somthing goes wrong!";
                  
             });
     
            
   }
   upload_chunk(e, type, tp, date) {
    this.uploadstatus = true;
    this.uploadstatus_message = "Please wait..";
    
    const CHUNK_SIZE = 5 * 1024 * 1024;  // 5MB per chunk
    const file = e.target.files[0]; // Assuming a single file is selected
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    
    let currentChunk = 0;
    let start = 0;
    let end = Math.min(start + CHUNK_SIZE, file.size);
    
        const uploadChunk = async () => {
        const chunk = file.slice(start, end);
        const form_data = new FormData();
        
        form_data.append('file', chunk);  // Append the current chunk
        form_data.append('type', type); 
        form_data.append('uby', localStorage.getItem("email"));
        form_data.append('tp', tp); 
        form_data.append('date', date); 
        form_data.append('chunk_index', currentChunk.toString());
        form_data.append('total_chunks', totalChunks.toString());
        form_data.append('filename', file.name);  // Send the filename

        
        
        try {
            // Make a POST request to upload the chunk
            const result = await this.service.postData1(form_data, 'upload_chuks');
            //console.log(`Chunk ${currentChunk + 1} of ${totalChunks} uploaded`);

            // Increment chunk counters
            currentChunk++;
            start = currentChunk * CHUNK_SIZE;
            end = Math.min(start + CHUNK_SIZE, file.size);
            
            // Check if there are more chunks to upload
            if (start < file.size) {
                uploadChunk();  // Continue uploading the next chunk
            } else {
                // All chunks are uploaded
                this.uploadstatus = true;
                this.uploadstatus_message = "File uploaded successfully!";
            }
        } catch (error) {
            console.error("Error uploading chunk", error);
            this.uploadstatus = true;
            this.uploadstatus_message = "Something went wrong!";
        }
    };

    // Start uploading the first chunk
    uploadChunk();
}

   filelist=[]
   f_flag=false;

   D_flag=false;
   D_i=-1;
   download(type,i) {

    this.D_i=i
  //   this.service.postData({}, 'download/'+type).then((result) => {
  //     var temp: any = result;
  //     this.resultr = temp
  //     //console.log(this.resultr);
  
  // })
  
  this.D_flag=true;
  this.service.download(type).then((result) => {
    this.D_flag=false;
    this.D_i=-1
  
    
  
  });
  }

   getallfiletype(){
    this.f_flag=true;
    
    this.service.postData({type:'ia'}, 'getallfiletype').then((result) => {
             var temp: any = result;
              //console.log(temp)
             
             this.filelist =temp.data
               
           this.f_flag=false;


       
       }).catch(e => {
         this.f_flag=false;
       });
   }
}
